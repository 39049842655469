<template>
  <section class="half d-flex flex-column justify-center grey lighten-5 half">
    <v-container class="py-16 my-md-16">
      <h4
        class="text-h6 text-sm-h5 text-md-h4 mb-8 text-center font-weight-bold"
      >
        What people are saying
      </h4>
      <blockquote
        class="blockquote py-2"
        v-for="(insight, i) in insights"
        :key="i"
      >
        <v-icon color="blue lighten-3" x-large>mdi-format-quote-close</v-icon
        ><span class="ml-3 text-h6">{{ insight.quote }}</span> &ndash;
        <em class="textcaption">{{ insight.designation }}</em>
      </blockquote>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 79">
      <path
        fill="#eeeeee"
        fillopacity="1"
        d="M0,0l80,5.3c80,5.7,240,15.7,400,32C640,53,800,75,960,69.3C1120,64,1280,32,1360,16l80-16v80h-80c-80,0-240,0-400,0s-320,0-480,0s-320,0-400,0H0V0z"
      ></path>
    </svg>
  </section>
</template>

<script>
export default {
  name: "Insights",
  data() {
    return {
      insights: [
        {
          quote:
            "Allows me to quickly evaluate benefits of different strategies and development plans.",
          designation: "Production Engineer, Permian Independent",
        },
        {
          quote: "Format is perfect, no excess information.",
          designation: "Analyst, Investment Banking Firm",
        },
        {
          quote: "Speed is phenomenal!",
          designation: "Senior Reservoir Engineer, Permian Independent",
        },
        {
          quote: "Ability to slice and dice is impressive.",
          designation: "Reserves Manager, Permian Independent",
        },
        {
          quote:
            "Like ability to add your own data to the system and run basic stats to see influence.",
          designation: "Completions Consultant, Delaware Project",
        },
        {
          quote: "Spent a week doing this, you did it in 10 seconds.",
          designation: "Reservoir Engineering Manager, Large Eagleford E&P",
        },
        {
          quote: "A democratized specialty tool set where anyone can come in and get an answer.",
          designation: "Reserves Manager, Large Multi-Basin Operator",
        },
      ],
    };
  },
};
</script>
