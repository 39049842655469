<template>
  <section
    id="contact"
    class="
      half
      d-flex
      flex-column
      justify-space-between
      grey
      darken-4
      grey--text
      text--lighten-5
    "
  >
    <v-container class="pb-4 pb-md-16">
      <h2 class="text-xs-h3 text-sm-h2 text-md-h1 mb-8">Get in touch</h2>
      <p class="text-xs-h6 text-sm-h5 text-md-h4">
        AlphaX Decision Sciences is based in Houston.
      </p>
      <v-row class="my-16">
        <v-col sm="6" lg="3">
          <v-card
            class="fill-height d-flex flex-column justify-space-between"
            light
          >
            <v-card-title>Contact Us</v-card-title>
            <v-card-text>
              <p class="mb-0">
                Phone:
                <a
                  href="tel:1-408-614-7146"
                  class="black--text text-decoration-none"
                  >+1 (408) 614 7146</a
                >
              </p>
              <p class="mb-0">
                Email:
                <a
                  href="mailto:admin@alphaxds.com"
                  class="black--text text-decoration-none"
                  >admin@alphaxds.com</a
                >
              </p>
            </v-card-text>
            <v-spacer />
            <v-card-actions class="pb-0">
              <v-btn
                light
                depressed
                block
                x-large
                href="https://www.linkedin.com/company/alphaxds"
                target="_blank"
                ><v-icon large class="mr-4" color="blue" left
                  >mdi-linkedin</v-icon
                >
                Connect with us</v-btn
              >
            </v-card-actions>
            <v-card-actions>
              <v-btn
                color="primary"
                block
                x-large
                depressed
                href="https://calendly.com/alphaxds"
                target="_blank"
                >Schedule Meeting</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="6" lg="3">
          <v-card class="fill-height" light>
            <v-img eager src="/images/houston.jpg"></v-img>
            <v-card-title> Houston, TX </v-card-title>
            <v-card-text>
              5051 Westheimer, 17th Floor <br />
              Houston, Texas 77056
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col sm="6" lg="3">
          <v-card class="fill-height" light>
            <v-img eager src="/images/sanjose.jpg"></v-img>
            <v-card-title>San Jose, CA</v-card-title>
            <v-card-text> PO Box 20700 <br> San Jose, California 95120 </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>

      <v-footer class="grey darken-4 white--text">
        <p>&copy; AlphaX Decision Sciences. All Rights Reserved.</p>
        <v-spacer></v-spacer>
      </v-footer>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Intro",
};
</script>
