<template>
  <section class="half d-flex flex-column justify-center grey lighten-3 half">
    <v-container class="py-16 my-md-16">
      <h2 class="text-h3 text-sm-h2 font-weight-bold">
        Independently screen, monitor, perform multivariate analysis on demand
      </h2>
      <p class="text-h6 text-sm-h5 text-md-h4 my-16">
        Sky is simple and intuitive, allowing a diverse group of stakeholders
        the ability to find answers quickly and easily.
      </p>

      <v-card>
        <v-row align="center">
          <v-col cols="6" md="3">
            <v-list shaped class="pa-0">
              <v-list-item-group v-model="selectedItem" color="primary" mandatory>
                <v-list-item v-for="(item, i) in items" :key="i" :value="item.title">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col>
            <v-card-text class="text-subtitle-1 text-md-h4 text--secondary font-weight-light pa-md-16">
              {{ selectedText }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 195">
      <path
        fill="#FAFAFA"
        d="M0,96l48,10.7C96,117,192,139,288,160s192,43,288,16S768,75,864,64s192,43,288,42.7c96,0.3,192-53.7,240-80 L1440,0v196h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V96z"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "Growth",
  data() {
    return {
      selectedItem: 'Reserves / Reservoir Engineers',
      items: [
        {
          title: "Reserves / Reservoir Engineers",
          text: "Offload the burden of rote forecasting and focus on solving multivariate problems by leveraging AI. Leverage a fast, independent evaluator of wells in new basins. Test different scenarios when forecasting undrilled inventory.",
        },
        {
          title: "Production Engineers",
          text: "Determine if wells are tracking to plan. Monitor more assets. Refine production optimization plans by testing key parameters against unbiased well forecasts.",
        },
        {
          title: "Completion Engineers",
          text: "Assess efficacy of new designs and benchmark performance against other companies. Test different completion design strategies on undrilled inventory.",
        },
        {
          title: "Investors",
          text: "Guard against subjectivity. Quickly reverse engineer type curves and forecasts provided by buyers/sellers. Maintain always-available evergreen forecasts of your E&P assets (both op and non-op). Automate KPIs to mitigate risk.",
        },
        {
          title: "Asset Managers",
          text: "Eliminate silos in multidisciplinary asset teams by providing them and your analysts with a unified view of well assets. Equip teams with the foresight to plan, execute, revise and adapt faster. ",
        },
        {
          title: "FP&A/ Planning",
          text: "Update existing or develop improved planning workflows using on-demand, unbiased PDP and PNDP well predictions. Automate the monitoring type curve shifts to mitigate against risk. Build KPI’s based on foresight. Facilitate better reporting.",
        },
        {
          title: "Facilities/Midstream",
          text: "Utilize on-demand, unbiased PDP and PNDP well predictions to update capacity constraints and type curve shifts. Midstream users can use Sky to screen and evaluate new E&P customers more quickly and efficiently.",
        },
        {
          title: "Analysts",
          text: "Independently perform sophisticated AI based basin wide analysis. Build KPIs that incorporate foresight. Build faster reporting workflows.",
        },
        {
          title: "Geologists",
          text: "Leverage our subsurface AI models to gain insights on new basins. Independently do performance analysis against landing zones and other parameters to optimize well placement. Perform rapid sensitivity analysis to evaluate various development strategies.",
        },
      ],
    };
  },
  computed: {
    selectedText() {
      if (!!this.selectedItem) {
        return this.items.find(e => e.title == this.selectedItem).text
      }
      return null
    }
  }
};
</script>
