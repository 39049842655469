<template>
  <section class="half d-flex flex-column grey lighten-5 half">
    <v-container class="pt-16 mt-md-16">
      <h2 class="text-h4 text-sm-h3 font-weight-bold">
        Out-of-the-box AI you can trust: Swift, easy, and hassle-free implementation
      </h2>
      <v-row align="center" class="my-16">
        <v-col>
          <ul class="text-subtitle-1 mb-5">
            <li>No training courses or certifications required</li>
            <li>No hidden costs and no 3<sup>rd</sup> party licenses</li>
            <li>No IT headaches - we just need access to your data to pull into our cloud-native Enterprise SaaS software</li>
            <li>2-factor authentication and a VPN will get you secure access anywhere</li>
          </ul>
          <h3 class="text-h6 mb-3">Available on</h3>
          
          <img src="/images/ienergy.png" height="50" />
          <img src="/images/aws.png" height="40" class="mx-5" />
          <img src="/images/microsoft.png" height="40" />
        </v-col>
        <v-col>
          <h3 class="text-h6 mb-3">We believe</h3>
          <v-text-field solo dark flat v-model="search" prepend-inner-icon="mdi-magnify" append-icon="mdi-microphone"></v-text-field>
          <v-card flat dark class="mt-n10" style=" border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; ">
            <v-list>
              <v-list-item-group v-model="selectedItem" color="blue">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <v-icon color="grey">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>software should be <strong :class="`${item.color}--text`">{{ item.text }}</strong></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 79">
      <path
        fill="#eeeeee"
        fillopacity="1"
        d="M0,0l80,5.3c80,5.7,240,15.7,400,32C640,53,800,75,960,69.3C1120,64,1280,32,1360,16l80-16v80h-80c-80,0-240,0-400,0s-320,0-480,0s-320,0-400,0H0V0z"
      ></path>
    </svg>
  </section>
</template>
<script>
export default {
  name: "Onboarding",
  data() {
    return {
      search: "software should be",
      selectedItem: 0,
      items: [
        { icon: "mdi-magnify", color: "blue", text: "fast" },
        { icon: "mdi-magnify", color: "amber", text: "simple" },
        { icon: "mdi-magnify", color: "green", text: "intuitive" },
        { icon: "mdi-magnify", color: "red", text: "powerful" },
        { icon: "mdi-magnify", color: "purple", text: "beautiful" },
      ],
    };
  },
};
</script>
