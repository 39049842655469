<template>
  <section class="half d-flex flex-column justify-center grey lighten-3 half">
    <v-container class="pt-16 mt-16">
      <h2 class="text-h3 text-sm-h2 font-weight-bold text-center">
        Benefits of UAI to different users
      </h2>
      <p class="text-h6 text-sm-h5 text-md-h4 my-16 text-center">
        Simple and intuitive, allows a diverse group of stakeholders
        the ability to find answers quickly and easily.
      </p>
      <v-row>
        <v-col cols="4" offset-lg="1">
          <v-card class="fill-height" color="transparent" flat>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-icon color="primary" style="font-size:128px;">mdi-lightbulb-on</v-icon>
              </div>
              <div class="flex-grow-1">
                <v-card-title>Executive Leadership</v-card-title>
                <v-card-text class="text-body-1">Vertical Integration <br/> Unbiased Monitoring</v-card-text>
              </div>
            </div>
          </v-card>
          
        </v-col>
        <v-col cols="5">
          <v-card class="fill-height" color="transparent" flat>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-icon color="secondary" style="font-size:128px;">mdi-finance</v-icon>
              </div>
              <div class="flex-grow-1">
                <v-card-title>Business and Financial Analysts</v-card-title>
                <v-card-text class="text-body-1">Empowered Self-Sufficiency <br/> Accelerated Strategic Planning </v-card-text>
              </div>
            </div>
          </v-card>
          
        </v-col>
        <v-col cols="5" offset="2">
          <v-card class="fill-height" color="transparent" flat>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-icon color="warning" style="font-size:128px;">mdi-cog</v-icon>
              </div>
              <div class="flex-grow-1">
                <v-card-title>Engineers and Technical Experts</v-card-title>
                <v-card-text class="text-body-1">Horizontal Collaboration <br/> Leveraging Proprietary Data <br/> Enhanced Technical Evaluations</v-card-text>
              </div>
            </div>
          </v-card>
          
        </v-col>
        <v-col cols="5">
          <v-card class="fill-height" color="transparent" flat>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-icon color="primary" style="font-size:128px;">mdi-currency-usd</v-icon>
              </div>
              <div class="flex-grow-1">
                <v-card-title>Investors</v-card-title>
                <v-card-text class="text-body-1">Unbiased Screening Process <br/> Proactive Asset Management</v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" offset="2" class="my-16">
          <v-img eager :src="`/images/end_users.svg`"></v-img>
        </v-col>
      </v-row>
      <v-row class="d-none">
        <v-col v-for="(item, i) in items" :key="i" cols="12" sm="6" md="4">
          <v-card class="fill-height">
            <v-card-title>{{ item.title }}</v-card-title>
            <v-card-text class="text-left text-body-1">{{ item.text }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <blockquote class="blockquote pt-16 text-center">
        <p class="text-h6 mb-0">
          <v-icon color="blue lighten-3" x-large>mdi-format-quote-close</v-icon>
          AlphaX has democratized a specialty tool set to where anyone can come
          in and get an answer.
        </p>
        &ndash; <em>Reserves Manager, Large Multi-Basin Operator</em>
      </blockquote>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 195">
      <path
        fill="#FAFAFA"
        d="M0,96l48,10.7C96,117,192,139,288,160s192,43,288,16S768,75,864,64s192,43,288,42.7c96,0.3,192-53.7,240-80 L1440,0v196h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V96z"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "Growth",
  data() {
    return {
      items: [
        {
          title: "Reserves Management",
          text: "Have an unbiased monthly production forecast of all your producing wells. Test different scenarios by forecasting undrilled inventory.",
        },
        {
          title: "Reservoir Engineers",
          text: "Offload the burden of rote forecasting and examine deeper problems associated with wells by leveraging AI to incorporate more parameters of interest.",
        },
        {
          title: "Production Engineers",
          text: "Refine production optimization plans based on checking how different variables affect the production forecast.",
        },
        {
          title: "Completion Engineers",
          text: "Perform rapid analysis of new designs and performance against other companies. Test different completion design strategies on undrilled inventory.",
        },
        {
          title: "Private Equity",
          text: "Have an unbiased evaluation of your E&P assets (both op and non-op) of all your wells all the time. As profiles change the forecasts will update instantly.",
        },
        {
          title: "A&D Groups",
          text: "Using AI perform rapid sensitivity analysis to look at development strategies to act on deals faster.",
        },
      ],
    };
  },
};
</script>
