import Vue from "vue";
import App from "./App.vue";
// import Etc from "./Etc.vue";
import vuetify from "./plugins/vuetify";
import "./assets/main.css";

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import router from "./router";

// const firebaseConfig = {
//   apiKey: "AIzaSyAW1WfrKtr31fGp-bRUZBuCKrNhkoqXhe4",
//   authDomain: "axsky-3d364.firebaseapp.com",
//   projectId: "axsky-3d364",
//   storageBucket: "axsky-3d364.appspot.com",
//   messagingSenderId: "927352667368",
//   appId: "1:927352667368:web:4d7b3fe9292bdc64f49d5d",
//   measurementId: "G-M6E7HPL0J9",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Vue.use(analytics);

// Plausible Analytics
import { VuePlausible } from 'vue-plausible'
Vue.use(VuePlausible, { 
  apiHost: 'https://analytics.alphaxds.com',
  enableAutoPageviews: true,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
