import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.base,
        secondary: colors.green.base,
        accent: colors.amber.base,
        error: colors.red.base,
        anchor: colors.grey.lighten5,
        background: colors.grey.lighten2,
      },
      dark: {
        primary: colors.blue.base,
        secondary: colors.green.base,
        accent: colors.amber.base,
        error: colors.red.base,
        anchor: colors.grey.lighten5,
        background: colors.grey.darken4,
      },
    },
  },
});
