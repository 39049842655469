<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].bckground}">
    <v-app-bar fixed hide-on-scroll flat color="transparent">
      <a href="https://sky.alphaxds.com">
        <v-img alt="AlphaXDS" class="shrink mr-2" contain eager src="/images/sky_logo_white.svg" width="237" />
      </a>
      <v-spacer/>
      <a href="https://alphaxds.com">
        <v-img alt="AlphaXDS" class="shrink mr-2 d-none d-md-block" contain eager src="/images/ax_logo_white.svg" width="237" />
      </a>
    </v-app-bar>
    <v-main>
      <banner />
      <growth />
      <onboarding />
      <publications />
      <advantage />
      <discover />
      <insights />
      <knowledge />
      <contact />
    </v-main>
  </v-app>
</template>

<script>
import Banner from '@/components/Banner.vue';
import Growth from '@/components/Growth.vue';
import Benefits from '@/components/Benefits.vue';
import Insights from '@/components/Insights.vue';
import Publications from '@/components/Publications.vue';
import Advantage from '@/components/Advantage.vue';
import Discover from '@/components/Discover.vue';
import Onboarding from '@/components/Onboarding.vue';
import Knowledge from '@/components/Knowledge.vue';
import Contact from '@/components/Contact.vue';

export default {
  name: "App",

  components: {
    Banner, Growth, Benefits, Insights, Publications, Advantage, Discover, Onboarding, Contact, Knowledge
  },

  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },

  data: () => ({
    //
  }),
};
</script>
