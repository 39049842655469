<template>
  <section class="half d-flex flex-column justify-center grey lighten-5 half">
    <v-container class="py-16 my-md-16">
      <v-expansion-panels flat>
        <v-expansion-panel class="grey lighten-5">
          <v-expansion-panel-header>
            <h2 class="text-h3 text-sm-h2 font-weight-bold text-center">
              How <span class="text--red">AlphaX Sky AI</span> creates competitive advantage
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align="center">
              <v-col cols="12" md="5" class="text-center text-md-right"
                ><h3 class="text-h5 font-weight-bold">
                  Finds relationships humans cannot
                </h3></v-col
              >
              <v-col cols="12" md="6" offset-md="1"
                ><img eager src="/images/how01.svg" contain
              /></v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="5" offset-md="1"
                ><img eager src="/images/how02.svg" contain
              /></v-col>
              <v-col
                cols="12"
                md="4"
                offset-md="1"
                class="text-center text-md-left"
                ><h3 class="text-h5 font-weight-bold">
                  Eliminates human bias
                </h3></v-col
              >
            </v-row>
            <v-row align="center">
              <v-col
                cols="12"
                md="4"
                offset=""
                class="text-center text-md-right"
                ><h3 class="text-h5 font-weight-bold">
                  Analyzes complex relationships
                </h3></v-col
              >
              <v-col cols="12" md="6" offset-md="1"
                ><img eager src="/images/how03.svg" contain
              /></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 134">
      <path
        fill="#EEEEEE"
        d="M0,128l48-26.7C96,75,192,21,288,10.7C384,0,480,32,576,32S768,0,864,0s192,32,288,53.3 c96,21.7,192,31.7,240,37.4l48,5.3v38h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V128z"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "Advantage",
};
</script>
