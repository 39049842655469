<template>
  <v-app class="topography" :style="{ backgroundColor: $vuetify.theme.themes[theme].background }">
    <v-container fill-height>
      <v-img src="@/assets/sky_logo_blue.svg" height="50" width="300" class="my-3" contain />
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" lgo="4">
          <v-form id="form" ref="form" @submit.prevent="sendEmail" v-model="valid" lazy-validation>
            <v-card flat class="pa-4">
              <v-card-title>Sign up for a trial</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col><v-text-field label="First name" name="first_name" :rules="required" /></v-col>
                  <v-col><v-text-field label="Last name" name="last_name" :rules="required" /></v-col>
                </v-row>
                <v-row>
                  <v-col><v-text-field label="Email address" name="email_address" :rules="emailRules" /></v-col>
                  <v-col><v-text-field label="Cell number" name="cell_number" :rules="phoneRules" hint="Required for two-factor authentication" persistent-hint /></v-col>
                </v-row>
                <v-row>
                  <v-col><v-text-field hidedetails label="Company name" name="company_name" :rules="required" /></v-col>
                  <v-col><v-text-field hidedetails label="Job title" name="job_title" :rules="required" /></v-col>
                </v-row>
                <v-checkbox :rules="required" label="I accept and understand the requirements for the trial" />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" type="submit" class="flex-grow-1" depressed :disabled="!valid">Accept</v-btn>
                <v-btn color="warning" class="flex-grow-1" depressed :to="{ name: 'home' }">Decline</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title>Terms and Conditions</v-card-title>
            <v-card-text class="text-caption">
              <p><strong>Disclaimer</strong>: Please read this disclaimer carefully before using our trial software SaaS subscription. By accessing or using the software, you agree to be bound by the terms and conditions of this disclaimer. By using the software, you acknowledge and accept that any risks associated with its use are solely your responsibility.</p>
              <ol class="mb-3">
              <li><strong>Data Ownership</strong>: While using our trial software SaaS subscription, you retain full ownership and control over your data. We do not claim any ownership rights to the data you input into the software.</li>
              <li><strong>Use at Your Own Risk</strong>: The trial software SaaS subscription is provided on an "as-is" basis. We do not provide any warranties, whether expressed or implied, regarding the functionality, reliability, or suitability of the software for specific purposes.</li>
              <li><strong>Data Privacy and Security</strong>: Please be aware that while we take reasonable measures to protect your data, it is important to note that no method of transmission or storage over the internet can be guaranteed as completely secure</li></ol>
              <p>To the fullest extent permitted by applicable law, we disclaim any liability for direct, indirect, incidental, consequential, or special damages arising from or related to the use or inability to use the trial software SaaS subscription.</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row><v-col><p class="d-block text-center text-caption">&copy; AlphaX Decision Sciences. All Rights Reserved.</p></v-col></v-row>
    </v-container>
  </v-app>
</template>
<script>
import emailjs from '@emailjs/browser';
export default {
  name: "Trial",
  data() {
    return {
      valid: false,
      accept: false,
      required: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Email address is required',
        v => /.+@.+\..+/.test(v) || 'Email address must be valid',
      ],
      phoneRules: [
        v => !!v || 'Cell number is required',
        v => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Cell number must be valid',
      ],
    };
  },
  computed: {
    theme() {
      return "light";
    },
  },
  methods: {
    sendEmail() {
      if (this.$refs.form.validate()) {
        emailjs.sendForm('service_gdpclzp', 'template_3ogcssi', "#form", process.env.VUE_APP_EMAILJS_KEY).then((result) => {
          alert('Your request has been submitted for approval. Thank you for applying!');
          this.$router.push({ name: 'home' });
        }, _ => {
          alert('There was a problem sending your request. Please try again leter!');
          this.$router.push({ name: 'home' });
        });
      }
    }
  }
};
</script>
<style scoped>
  .topography { background: url('../assets/topography.svg'); }
</style>