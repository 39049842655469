<template>
  <section class="half d-flex flex-column grey lighten-3 half">
    <v-container class="py-16 my-md-16">
      <h2 class="text-h3 text-sm-h2 font-weight-bold mb-8">
        Discover more with your data
      </h2>
      <p class="text-h6 text-sm-h5">
        By combining your proprietary and public data to drive our basin
        specific AI models you can now get highly accurate forecasts on demand,
        enabling users to get the following in seconds: AI Driven Well
        Forecasting, Completions Design Analysis, Evaluating A&amp;D
        Opportunities, Type Curve Generation, Well Forecasting, Virtual Wells
        and Competitor Analysis
      </p>

      <h3 class="text-h3 mt-16 mb-4">Available Basins</h3>
      <v-row align="stretch">
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Anadarko </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Appalachian (Marcellus, Utica, Devonian) </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Barnett </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Denver-Julesburg </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Eagle Ford </v-card-title></v-card></v-col>
      </v-row>
      <v-row align="stretch" class="mb-16">
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Haynesville </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Permian (Midland, Delaware, Central) </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Powder River </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> San Juan </v-card-title></v-card></v-col>
        <v-col><v-card class="fill-height"><v-card-title class="text-no-wrap text-center"> Williston (Bakken) </v-card-title></v-card></v-col>
      </v-row>

      <v-row align="center" class="my-16">
        <v-col>
          <h3 class="text-h6 mb-3">Generate program conditions quickly</h3>
          <dl class="text-subtitle-1">
            <dd>
              Pick which filters to incorporate from the data when generating
              predictions
            </dd>
            <dd>
              Focus on specific program conditions by applying one or more
              filters
            </dd>
            <dd>
              Save groups of wells and save sessions - no need to recreate the
              scenario
            </dd>
          </dl>
        </v-col>
        <v-col md="auto">
          <v-img eager class="elevation-2" src="/images/filters.gif"></v-img>
        </v-col>
      </v-row>

      <v-row align="center" class="my-16">
        <v-col class="text-left" order="1" order-lg="12">
          <h3 class="text-h6 mb-3">Rapid performance analysis</h3>
          <p class="text-subtitle-1">
            Instantly look at summary statistics, including production and EURs
            through different aggregate methods and analysis metrics along
            multiple dimensions
          </p>
        </v-col>
        <v-col cols="auto" order="12" order-lg="1">
          <v-img eager class="elevation-2" src="/images/summary.gif"></v-img>
        </v-col>
      </v-row>

      <v-row align="center" class="my-16">
        <v-col>
          <h3 class="text-h6 mb-3">Interactive, real-time visualizations</h3>
          <p class="text-subtitle-1">
            Instantly visualize wells in groups or through individual profiles,
            type curves, and probit plots. Curate, edit and save real-time -
            even in dark mode.
          </p>
        </v-col>
        <v-col cols="auto">
          <v-img eager class="elevation-2" src="/images/aggregates.gif"></v-img>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-16">
        <v-col class="text-left" order="1" order-lg="12">
          <h3 class="text-h6 mb-3">Gamified, Intuitive UI</h3>
          <p class="text-subtitle-1">
            No training needed to understand how to find and group the wells you
            need via the map, filters, searching bar, or manually picking them
            from a list
          </p>
        </v-col>
        <v-col cols="auto" order="12" order-lg="1">
          <v-img eager class="elevation-2" src="/images/map.gif"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 196">
      <path
        fill="#FAFAFA"
        d="M0,96l48,10.7C96,117,192,139,288,160s192,43,288,16S768,75,864,64s192,43,288,42.7c96,0.3,192-53.7,240-80 L1440,0v196h-48c-48,0-144,0-240,0s-192,0-288,0s-192,0-288,0s-192,0-288,0s-192,0-240,0H0V96z"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "Discover",
};
</script>
