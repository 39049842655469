import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/Main.vue";
import TrialView from "../views/Trial.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "home", component: MainView },
  { path: "/trial", name: "trial", component: TrialView },
];

const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

export default router;
